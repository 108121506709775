.questions_wrapper {
    flex: 1 0 auto; 
    max-width: 94vw; 
    margin: 7px auto;  
    padding: 0 10px;
    .admin_dashboard_list {
        padding: 0 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        .question_table {
            font-size: 14px;
            overflow-x: auto;
            .question_table_head {
                text-align: center;
            }
            .question_td {
                text-align: left;
                padding: 0 5px;
            }
            .question_name {
                min-width: 270px;
            }
            .question_email {
                min-width: 250px;
            }
            .question_phone {
                min-width: 100px;
                text-align: center;
            }
            .question_add_answer {
                text-align: center;
                width: 250px;
            }
            .question_date {
                min-width: 120px;
            }
            .question_status {
                min-width: 138px;
                text-align: center;
            }
            .question {
                cursor: pointer;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 250px;
                &:hover {
                    color: blue;
                }
            }
        }
    }
    .pagination_container{
        display: flex;
        justify-content: flex-end;
        margin: 20px 10px 20px 0;
    }
    .delete{
        color: red;
        cursor: pointer;
    }
    .answered{
        color: blue;
    }
    .answer{
        color: red;
        // color: green;
        // cursor: pointer;
        // &:hover{
        //     text-decoration: underline;
        // }
    }
}

@media screen and (max-width: 800px) {
    .questions_wrapper {
        margin-top: 60px;
    }
}
