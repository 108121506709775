.show-bills-pop-up-wrapper {
    .show-result-pop-up-header {
        .title {
            font-size: 16px;
            line-height: 28px;
            color: #000000;
        }
    }
    .popup-content {
        padding: 16px !important;
        width: 500px !important;
        position: relative;
        padding: 5px;
    }
    .list_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-right: 32px;
        }
    }
    .list_content {
        max-height: 250px;
        overflow-x: auto;
        padding-right: 16px;
    }
    .exit {
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #474747;
        border: 1px solid rgba(71, 71, 71, 0.6);
        box-sizing: border-box;
        border-radius: 4px;
        width: 160px;
        height: 32px;
        margin-top:16px ;
        cursor: pointer;
    }
}

@media screen and (max-width: 600px) {
    .show-bills-pop-up-wrapper {
        .popup-content {
            width: 280px !important;
        }
    }
}