.create-counter-admin-pop-up-wrapper{
    .popup-content {
        width: 280px !important;
        padding: 10px !important;
    }
    .content {
        text-align: center;
        margin: 10px 0;
        .title {
            font-size: 23px;
            color: #000000;
            margin: 0 0 10px 0;
        }
        .form_item {
            text-align: left;
            margin-top: 16px;
            .form_label {
                font-size: 12px;
                line-height: 14px;
                color: #474747;
            }
            .form_input {
                border: 1px solid rgba(172, 172, 172, 0.6);
                box-sizing: border-box;
                border-radius: 2px;
                height: 32px;
                padding-left: 8px;
                width: 100%;
                &.error{
                    border: 1px solid rgba(157, 16, 16, 0.75);
                }
            }
            .viewPassword {
                position: absolute;
                margin-left: 281px;
                margin-top: 8px;
                cursor: pointer;
            }
        }
        .btn_wrapper {
            margin-top: 15px;
            text-align: center;
            display: inline-block;
            border: none;
            background: none;
            &:focus{
                outline: none;
            }
        }
        .forgot_password {
            font-size: 12px;
            line-height: 14px;
            color: #526477;
            cursor: pointer;
            &:hover{
                color: #007BB0;
            }
        }
        .no_account{
            font-size: 12px;
            display: block;
            margin-top: 33px;
            cursor: pointer;
            b {
                color: #007BB0;
            }
        }
    }
}