.comment_wrapper {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    min-height: 50px;
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 28px;
    width: 300px;
    z-index: 90;
    input {
        margin: 8px 0px 0px 8px;
        border: none;
        color: red;
        width: 90%;
        font-size: 14px;
        line-height: 16px;
        &:focus{
            outline: none;
        }
    }
    .text {
        width: 95%;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        line-height: 21px;
        margin: 5px;
        &.no_info {
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .buttons_wrapper {
        margin-left: auto;
        display: table;
        margin-bottom: 8px;
        .action {
            cursor: pointer;
            font-weight: bold;
            margin-right: 12px;
            &.cancel {
                color: red;
            }
            &.edit {
                color: #007BB0;
            }
        }
    }
}