.pagination_wrapper {
    display: flex;
    align-items: center;
    .page_counter {
        display: flex;
        align-items: flex-end;
        margin-right: 10px;
        .page_counter_select {
            border: 1px solid rgba(172, 172, 172, 0.6);
            box-sizing: border-box;
            border-radius: 2px;
            width: 50px;
            cursor: pointer;
        }
        .page_counter_totalPage {
            margin-left: 5px;
        }
    }
    .arrows {
        display: flex;
        align-items: flex-end;
        margin-left: 5px;
        .arrows_image {
            width: 24px;
            cursor: pointer;
            &:hover {
                background-color: rgba(172, 172, 172, 0.6);
            }
        }
        .rotate {
            transform: rotate(180deg);
        }
    }
}