.counters_page {
    flex: 1 0 auto;
    max-width: 94vw;
    margin: 7px auto;
    padding: 0 10px;
    .counters_dashboard_list {
        padding: 0 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        .counters_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 15px;
            .search {
                display: flex;
                .counters_search {
                    border: 1px solid rgba(172, 172, 172, 0.6);
                    box-sizing: border-box;
                    border-radius: 4px;
                    color: #ACACAC;
                    font-size: 12px;
                    line-height: 14px;
                    padding: 0 6px;
                    width: 200px;
                    height: 32px;
                    margin-right: 8px;
                }
            }
        }
        .counters_wrapper {
            font-size: 14px;
            overflow-x: auto;
            .counters_table {
                border-collapse: collapse;
                width: 100%;
                text-align: center;
                tr {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    th {
                        padding: 2px 5px;
                    }
                    td {
                        padding: 12px 5px;
                    }
                    .counter_number_td {
                        min-width: 130px;
                    }
                    .quarter_number_td {
                        min-width: 60px;
                        text-align: center;
                    }
                    .plomb_number_td {
                        min-width: 105px;
                        text-align: center;
                    }
                    .adress_td {
                        min-width: 200px;
                    }
                    .name_td {
                        min-width: 280px;
                    }
                    .last_number_th {
                        width: 80px;
                    }
                    .last_number_td {
                        min-width: 80px;
                    }
                    .status_td {
                        min-width: 130px;
                    }
                    .counter_menu_td {
                        min-width: 72px;
                        text-align: left;
                    }
                    .counters_filters {
                        width: 130px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span {
                            width: min-content;
                            margin-right: 3px;
                        }
                        .filters{
                            img {
                                cursor: pointer;
                                width: 12px;
                                display: block;
                                padding: 2px;
                                margin: 2px 0;
                                border-radius: 2px;
                                &:hover {
                                    background-color: rgba(172, 172, 172, 0.6);
                                }
                                &.active{
                                    background-color: rgba(172, 172, 172, 0.6);
                                }
                            }
                        }
                    }
                }
            }
        }
        .pagination_container {
            display: flex;
            justify-content: flex-end;
            margin: 20px 10px 20px 0;
            &_top {
                margin: 0 10px 15px 0;
            }
        }
        .delete {
            color: red;
            cursor: pointer;
        }
        .edit {
            color: blue;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 800px) {
    .counters_page {
        margin-top: 60px;
    }
}

@media screen and (max-width: 600px) {
    .counters_page {
        .counters_dashboard_list {
            .counters_header {
                justify-content: flex-end;
                flex-wrap: wrap;
                .search {
                    justify-content: flex-end;
                    width: 100%;
                    margin-top: 20px;
                    .counters_search {
                        width: 140px;
                    }
                }
            }
        }
    }
}