.personal-data {
    width: 550px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 24px;
    margin: 8px;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            font-weight: bold;
        }
    }
    .content {
        margin: 32px 0;
        .line {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            span {
                text-align: left;
                display: block;
                font-size: 18px;
                line-height: 21px;
                color: #000000;
            }
        }
    }
    .awaiting_confirm {
        margin-top: 80px;
        &_text {
            margin: 0 0 10px 0;
            color: red;
            font-size: 18px;
            font-weight: 600;
        }
    }
    .resent_confirm_email {
        font-size: 16px;
        cursor: pointer;
        margin: 0 0 10px 0;
        color: rgb(0, 123, 176);
        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 800px) {
    .personal-data {
        width: 75%;
        margin: 24px 0px;
    }
}

@media screen and (max-width: 600px) {
    .personal-data {
        width: 100%;
        padding: 15px;
    }
}

@media screen and (max-width: 450px) {
    .personal-data {
        .content {
            .line {
                flex-wrap: wrap;
                span {
                    width: 100%;
                    font-size: 16px;
                }
            }
        }
    }
}