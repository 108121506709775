.sign_up_page {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .sign_up_wrapper {
        width: 304px;
        background: #FFFFFF;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 20px 40px;
        .content {
            margin: 0;
            text-align: center;
            .title {
                font-size: 32px;
                color: #000000;
            }
            .form_item {
                position: relative;
                text-align: left;
                margin-top: 16px;
                .form_label {
                    font-size: 12px;
                    line-height: 14px;
                    color: #474747;
                }
                .form_input {
                    border: 1px solid rgba(172, 172, 172, 0.6);
                    box-sizing: border-box;
                    border-radius: 2px;
                    height: 32px;
                    padding: 0 26px 0 8px;
                    width: 100%;
                    &.error{
                        border: 1px solid rgba(157, 16, 16, 0.75);
                    }
                }
                .viewPassword {
                    position: absolute;
                    top: 30px;
                    right: 8px;
                    cursor: pointer;
                }
            }
            .btn_wrapper {
                margin-top: 15px;
                text-align: center;
                display: inline-block;
                border: none;
                background: none;
                &:focus{
                    outline: none;
                }
            }
            .forgot_password {
                font-size: 12px;
                line-height: 14px;
                color: #526477;
                cursor: pointer;
                &:hover{
                    color: #007BB0;
                }
            }
            .no_account{
                font-size: 12px;
                display: block;
                margin-top: 20px;
                cursor: auto;
                b {
                    cursor: pointer;
                    color: #007BB0;
                }
            }
        }
    }
}

.error_text{
    font-size: 9px;
    color: rgba(157, 16, 16, 0.75);
    &.server{
        font-size: 12px;
        text-align: left;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 480px) {
    .sign_up_page {
        .sign_up_wrapper {
            width: 230px;
            padding: 10px 30px;
            .content {
                .title {
                    margin-top: 5px;
                    font-size: 26px;
                }
            }
        }
    }
}