.view-report-admin-pop-up-wrapper {
    text-align: center;
    .view_report_admin_popup-content {
        padding: 16px !important;
        width: 600px !important;
        .data {
            height: 424px;
            overflow-y: auto;
            font-weight: normal;

            .biling_data_header{
                display: flex;
                justify-content: space-between;
                padding: 0px 12px;

                .prepayment_form{
                    display: flex;
                    align-items: center;
                    margin-left: 2px;
                    .prepayment{
                        margin-right: 6px;
                        height: 20px;
                    }
                    .prepayment_form_btn{
                        
                        color: green;
                        cursor: pointer;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .edit {
                margin: 6px;
            }
            .show_user_btn_wrapper {
                display: flex;
                margin-top: 14px;
                div {
                    cursor: pointer;
                    font-size: 24px;
                    line-height: 16px;
                    margin-left: 8px;
                    &.confirm {
                        color: #007BB0;
                    }
                    &.delete {
                        color: red;
                    }
                    &.change_billing {
                        color: #007BB0;
                        font-size: 14px;
                    }
                }
            }
        }
        .bills {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
        .results {}
        .title {
            font-size: 24px;
        }
        .tabs {
            display: flex;
            .item {
                font-size: 17px;
                margin: 0 8px;
                cursor: pointer;
                &.active {
                    color: blue;
                }
            }
        }
    }
}

@media screen and (max-width: 680px) {
    .view-report-admin-pop-up-wrapper {
        .view_report_admin_popup-content {
            padding: 5px !important;
            width: 300px !important;
        }
    }
}