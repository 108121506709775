.dashboard_wrapper {
    flex: 1 0 auto;
}
.dashboard_wrapper_admin {
    flex: 1 0 auto;
    max-width: 94vw;
    margin: 7px auto;
    padding: 0 10px;
}

@media screen and (max-width: 800px) {
    .dashboard_wrapper_admin {
        margin-top: 60px;
    }
}