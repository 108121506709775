.delete-pop-up-wrapper {
    .popup-content {
        padding: 16px !important;
        width: 280px !important;
    }
    .header {
        text-align: center;
        .delete_title {
            font-size: 18px;
            line-height: 28px;
            color: #000000;
        }
        .change_password {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #007BB0;
            cursor: pointer;
        }
    }
    .btn_wrapper {
        margin-top: 26px;
        text-align: center;
        display: flex;
        border: none;
        background: none;
        margin-left: auto;
        margin-right: auto;
        &:focus {
            outline: none;
        }
        .cancel_btn {
            font-size: 14px !important;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #474747;
            border: 1px solid rgba(71, 71, 71, 0.6);
            box-sizing: border-box;
            border-radius: 4px;
            height: 32px;
            width: 120px;
            margin-left: 16px;
            cursor: pointer;
        }
    }
}