.basic-list-item-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #000000;

    .label{
        font-size: 14px;
    }

    .value{
        font-size: 14px;
    }

}