.edit-profile-pop-up-wrapper {
    .edit_profile_popup-content {
        padding: 16px !important;
        width: 530px !important;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 !important;
        .title {
            font-size: 24px;
            line-height: 28px;
            color: #000000;
        }
        .change_data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .change_password {
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.01em;
                margin: 0px 0px 10px;
                color: #007BB0;
                cursor: pointer;
            }
        }
    }
    .form_edit_profile {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        text-align: left;
        .form_item {
            margin: 0px 8px;
            .form_label {
                font-size: 12px;
                line-height: 14px;
                color: #474747;
            }
            .form_input {
                border: 1px solid rgba(172, 172, 172, 0.6);
                box-sizing: border-box;
                border-radius: 2px;
                width: 160px;
                height: 32px;
                padding: 0 6px;
                &.error{
                    border: 1px solid rgba(157, 16, 16, 0.75);
                }
                &.email {
                    width: 340px;
                }
            }
        }
    }
    .btn_wrapper {
        margin-top: 26px;
        text-align: center;
        display: flex !important;
        border: none;
        background: none;
        margin-left: auto;
        &:focus {
            outline: none;
        }
        .cancel {
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #474747;
            border: 1px solid rgba(71, 71, 71, 0.6);
            box-sizing: border-box;
            border-radius: 4px;
            height: 32px;
            width: 125px;
            margin-left: 16px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 600px) {
    .edit-profile-pop-up-wrapper {
        .edit_profile_popup-content {
            width: 280px !important;
        }
        .header {
            flex-wrap: wrap;
        }
        .form_edit_profile {
            max-height: 280px;
            overflow-y: auto;
            .form_item {
                .form_input {
                    width: 250px;
                }
            }
        }
    }
}