.personal_info_page {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .personal_info_wrapper {
        width: 700px;
        background: #FFFFFF;
        border-radius: 8px 0px 0px 8px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        display: flex;
        .left_nav {
            width: 222px;
            background: #3A8BCB;
            border-radius: 8px 0px 0px 8px;
            padding: 124px 20px;
            font-size: 16px;
            color: #FFFFFF;
            .item {
                display: flex;
                align-items: center;
                margin-top: 14px;
                &.grey {
                    color: #D6E1E6;
                    .number {
                        background: #D6E1E6;
                    }
                }
                .number {
                    background: white;
                    color: #000000;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .succes {
                    margin-left: 6px;
                    margin-right: 5px;
                }
            }
            .text {
                margin-left: 8px;
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .personal_info_page {
        .personal_info_wrapper {
            width: 400px;
            .left_nav {
                width: 30px;
                padding: 124px 5px;
                .text {
                    display: none;
                    margin-left: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .personal_info_page {
        .personal_info_wrapper {
            width: 290px;
            .left_nav {
                padding: 144px 5px 124px 5px;
            }
        }
    }
}