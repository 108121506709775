.email_sent_wrapper {
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
    text-align: center;
    .title {
        font-size: 48px;
    }
    .desc {
        font-size: 24px;
        margin-top: 32px;
    }
    .goback {
        font-size: 18px;
        margin-top: 64px;
        margin-bottom: 30px;
        b {
            color:rgb(0, 123, 176);
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .gosignin {
        font-weight: bold;
        cursor: pointer;
        font-size: 20px;
        color: rgb(0, 123, 176);
        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 550px) {
    .email_sent_wrapper {
        .title {
            font-size: 30px;
        }
        .desc {
            font-size: 20px;
        }
        .goback {
            font-size: 16px;
        }
        .gosignin {
            font-size: 18px;
        }
    }
}