.edit-result-pop-up-wrapper {
    .add-result-pop-up-header {
        .title {
            font-size: 24px;
            line-height: 28px;
            color: #000000;
        }
    }
    .edit_result_popup-content {
        padding: 0 !important;
        width: 500px !important;
        position: relative;
        background: none !important;
        border: none !important;
    }
    .error{
        color: #C2145B;
        font-size: 12px;
        background:white;
    }
    .save {
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        color: #007BB0;
        width: 160px;
        height: 32px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;
        cursor: pointer;
    }
    .cancel {
        font-size: 12px;
        line-height: 14px;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        margin-top: 16px;
    }
    .counter {
        padding: 40px;
        border-radius: 50%;
        text-align: center;
        background: #007BB0;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        width: 350px;
        height: 350px;
        margin-left: auto;
        margin-right: auto;
        .numbers {
            .number {
                box-sizing: border-box;
                outline: none;
                border: none;
                background: #FFFFFF;
                border-radius: 4px;
                width: 30px;
                height: 34px;
                margin: 4px;
                color: #ACACAC;
                font-size: 22px;
                padding-left: 8px;
                &.five {
                    color: red;
                    &::placeholder {
                        color: red
                    }
                }
                &.six {
                    color: red;
                    &::placeholder {
                        color: red
                    }
                }
            }
        }
        .text {
            color: #FFFFFF;
            &._12 {
                font-size: 16px;
                margin-top: 43px;
            }
            &._14 {
                font-size: 18px;
            }
            &._18 {
                font-size: 24px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .edit-result-pop-up-wrapper {
        .edit_result_popup-content {
            width: 320px !important;
        }
        .save {
            margin-top: 15px;
        }
        .counter {
            width: 240px;
            height: 240px;
            .numbers {
                .number {
                    width: 26px;
                    height: 28px;
                    margin: 2px;
                    font-size: 16px;
                }
            }
            .text {
                &._12 {
                    font-size: 14px;
                    margin-top: 10px;
                }
                &._14 {
                    font-size: 16px;
                }
                &._18 {
                    font-size: 20px;
                }
            }
        }
    }
}