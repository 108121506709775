.menu {
    display: none;
}
.navbar {
    background: #526477;
    flex-shrink: 0;
    .navbar-wrapper {
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .navbar_items {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 65px;
        }
        .nav_item {
            cursor: pointer;
            font-size: 18px;
            line-height: 21px;
            color: #FFFFFF;
            margin: 0 3px;
            padding: 0 5px;
            height: 100%;
            display: flex;
            align-items: center;
            transition: all .2s ease;
            &:hover {
                background: rgba(255, 255, 255, 0.25);
            }
            &:focus {
                background: rgba(255, 255, 255, 0.25);
            }
            &.exit {
                height: 65px;
            }
            &.active {
                background: rgba(255, 255, 255, 0.25);
            }
        }
    }
}

.inbox_questions {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
    background: white;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    color: #526477;
    transform: translate(0, -8%)
}

@media screen and (max-width: 800px) {
    .navbar {
        display: none;
    }
    .menu {
        display: block !important;
    }
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 24px;
    top: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #526477;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0px;
}

/* General sidebar styles */
.bm-menu {
    background: #526477;
    padding: 1em 1em 0;
    font-size: 1.3em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #FFFFFF;
    padding: 6px 14px 6px 6px;
}

/* Individual item */
.bm-item {
    display: inline-block;
    padding: 8px;
    margin: 4px 0;

    &.active {
        background: rgba(255, 255, 255, 0.25);
    }

    &:hover {
        background: rgba(255, 255, 255, 0.25);
    }

    &:focus {
        outline: none;
    }
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0px;
}