.about_us_wrapper {
    flex: 1 0 auto;
    .about_us {
        max-width: 1200px;
        margin: 40px auto;
        padding: 0 20px;
        .title_about {
            margin: 0 0 30px 0;
            text-align: left;
            font-size: 36px;
            line-height: 42px;
            color: #01000F;
        }
        .desc_about {
            text-align: left;
            font-size: 16px;
            line-height: 19px;
            color: #01000F;
            font-weight: 400;
        }
        .subtitle_about {
            margin: 10px 0;
            font-style: italic;
        }
    }
}