.reset_password_page {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .reset_password_wrapper {
        width: 304px;
        background: #FFFFFF;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 15px 40px;
        .content {
            text-align: center;
            .title {
                font-size: 32px;
                color: #000000;
            }
            .form_item {
                position: relative;
                text-align: left;
                margin-top: 16px;
                .viewPassword { 
                    position: absolute;
                    top: 22px;
                    right: 8px;
                    cursor: pointer;
                }
                .form_label {
                    font-size: 12px;
                    line-height: 14px;
                    color: #474747;
                }
                .form_input {
                    border: 1px solid rgba(172, 172, 172, 0.6);
                    box-sizing: border-box;
                    border-radius: 2px;
                    height: 32px;
                    padding: 0 26px 0 8px;
                    width: 100%;
                    &.error{
                        border: 1px solid rgba(157, 16, 16, 0.75);
                    }
                }
                .viewPassword {
                    position: absolute;
                    margin-left: 281px;
                    margin-top: 8px;
                    cursor: pointer;
                }
            }
            .btn_wrapper {
                margin-top: 26px;
                text-align: center;
                display: inline-block;
                border: none;
                background: none;
                &:focus{
                    outline: none;
                }
            }
            .forgot_password {
                font-size: 12px;
                line-height: 14px;
                color: #526477;
                cursor: pointer;
                &:hover{
                    color: #007BB0;
                }
            }
            .no_account{
                font-size: 12px;
                display: block;
                margin-top: 33px;
                cursor: pointer;
                b {
                    color: #007BB0;
                }
            }
        }
    }
}
.error_text{
    font-size: 9px;
    color: rgba(157, 16, 16, 0.75);
}

@media screen and (max-width: 480px) {
    .reset_password_page {
        .reset_password_wrapper {
            width: 230px;
            padding: 10px 30px;
            .content {
                text-align: center;
                .title {
                    font-size: 26px;
                }
            }
        }
    }
}