.user_dashboard_wrapper {
    max-width: 1200px;
    margin: 40px auto 60px auto;
    padding: 0 20px;
    .user_dashboard_wrapper_title {
        text-align: center;
        font-size: 48px;
        line-height: 56px;
        color: #000000;
        font-weight: bold;
    }
    .content {
        text-align: left;
        .blocks_line {
            display: flex;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 800px) {
    .user_dashboard_wrapper {
        .user_dashboard_wrapper_title {
            font-size: 42px;
            text-align: left;
            padding-right: 40px;
        }
        .content {
            .blocks_line {
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .user_dashboard_wrapper {
        .user_dashboard_wrapper_title {
            font-size: 36px;
        }
    }
}