.admin_dashboard_list {
    font-size: 14px;
    overflow-x: auto;
     table {
         width: 100%;
         border-collapse: collapse; 
         tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            height: 48px;
            th {
                padding: 0 5px;
                text-align: center;
                .counters {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .filters {
                        padding-left: 6px;
                        img {
                            cursor: pointer;
                            width: 12px;
                            display: block;
                            padding: 2px;
                            margin: 1px 0;
                            border-radius: 2px;
                            &:hover {
                                background-color: rgba(172, 172, 172, 0.6);
                            }
                            &.active {
                                background-color: rgba(172, 172, 172, 0.6);
                            }
                        }
                    }
                }
            }
            td {
                text-align: center;
                padding: 0 5px;
                img {
                    cursor: pointer;
                }
                .active_notif {
                    border: 2px solid #3CC13B;
                    border-radius: 4px;
                }
                .status {
                    border-radius: 10px;
                    font-size: 14px;
                    padding: 5px;
                    display: inline-block;
                    width: auto;
                    font-weight: 400;
                    &.debtor {
                        color: #F03738;
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F03738;
                    }
                    &.paid {
                        color: #3CC13B;
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3CC13B;
                    }
                }
                .show_user {
                    cursor: pointer;
                    &:hover{
                        color: green;
                    }
                }
                .edit_user {
                    cursor: pointer;
                    &:hover {
                        color: blue;
                    }
                }
            }
            .user_name_td {
                min-width: 280px;
            }
            .user_comment_td {
                min-width: 20px;
                position: relative;
            }
            .user_counter_num_td {
                min-width: 110px;
            }
            .user_quarter_td {
                min-width: 58px;
            }
            .user_email_td {
                min-width: 230px;
            }
            .user_sent_reminder_td {
                min-width: 14px;
            }
            .user_phone_number {
                min-width: 120px;
            }
            .user_is_paid_td {
                min-width: 140px;
            }
            .user_status_td {
                min-width: 138px;
            }
            .user_menu_td {
                min-width: 72px;
            }
            .await_confirm {
                 color: limegreen;
                 cursor: pointer;
                 text-decoration: underline;
            }
        }
    }
}
