.change-password-pop-up-wrapper {
    text-align: center;
    .change_password_popup-content {
        width: 280px !important;
        padding: 15px !important;
    }
    .change-password-header {
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            font-size: 24px;
            line-height: 28px;
            color: #000000;
        }
        .change_password {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #007BB0;
            cursor: pointer;
        }
    }
    form {
        text-align: center;
    }
    .form_item {
        position: relative;
        margin: 0px 8px;
        .viewPassword_changepassword {
            position: absolute;
            top: 30px;
            right: 20px;
            cursor: pointer;
        }
        .form_label {
            font-size: 12px;
            line-height: 14px;
            color: #474747;

        }
        .form_input {
            border: 1px solid rgba(172, 172, 172, 0.6);
            box-sizing: border-box;
            border-radius: 2px;
            width: 240px;
            height: 32px;
            padding: 0 25px 0 6px;
            &.error {
                border: 1px solid rgba(157, 16, 16, 0.75);
            }
            &.email {
                width: 340px;
            }
        }
    }
    .btn_wrapper_center {
        margin-top: 26px;
        text-align: center;
        display: flex;
        border: none;
        background: none;
        margin-left: auto;
        margin-right: auto;
        &:focus {
            outline: none;
        }
        .cancel {
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #474747;
            border: 1px solid rgba(71, 71, 71, 0.6);
            box-sizing: border-box;
            border-radius: 4px;
            height: 32px;
            width: 125px;
            margin-left: 16px;
            cursor: pointer;
        }
    }
}