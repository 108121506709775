.feedback_wrapper {
    flex: 1 0 auto;
    .feedback {
        max-width: 1200px;
        margin: 40px auto 60px auto;
        padding: 0 20px;
        .title_wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .title {
            margin: 0 40px 0 0;
            text-align: left;
            font-size: 36px;
            line-height: 42px;
            color: #000000;
        }
        .sub_title {
            text-align: left;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
            margin-top: 30px;
        }
        .form_item {
            text-align: left;
            margin-top: 16px;
            .form_label {
                font-size: 12px;
                line-height: 14px;
                color: #474747;
                font-weight: bold;
            }
            .btn_file {
                margin-top: 10px;
            }
            .form_input {
                border: 1px solid rgba(172, 172, 172, 0.6);
                box-sizing: border-box;
                border-radius: 2px;
                height: 32px;
                padding-left: 8px;
                width: 278px;
                &.error {
                    border: 1px solid rgba(157, 16, 16, 0.75);
                }
            }
            .error_text {
                font-size: 12px;
            }
            .form_textarea {
                height: 100px;
                width: 410px;
                resize: none;
            }
            .textarea_lenght {
                margin: 0;
                font-size: 12px;
            }
            .input_type_file {
                cursor: pointer;
                background-color: rgba(0, 0, 0, .5);
                padding: 2px 15px 3px 15px;
                border-radius: 3px;
                color: white;
            }
            .attachment_name {
                margin-left: 5px;
                font-size: 14px;
                font-weight: bold;
                .attachment_delete {
                    margin-left: 3px;
                    cursor: pointer;
                }
            }
        }
        .btn_wrapper {
            display: flex;
            justify-content: left;
            border: none;
            background: none;
            width: 156px;
            margin: 30px 0 20px 0;
            padding: 0;
            &:focus {
                outline: none;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .feedback_wrapper {
        .feedback {
            .title_wrapper {
                flex-wrap: wrap;
            }
            .title {
                flex-basis: 100%;
                margin-bottom: 20px;
            }
            .sub_title {
                margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 460px) {
    .feedback_wrapper {
        .feedback {
            .form_item {
                .form_textarea {
                    width: 278px;
                }
                .attachment_name {
                    display: block;
                }
            }
        }
    }
}
