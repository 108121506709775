.pay-pop-up-wrapper {
    .pay-pop-up-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .title {
            font-size: 16px;
            line-height: 28px;
            color: #000000;
        }
        .pay_all {
            .form_pay_all {
                display: flex;
                align-items: center;
                position: relative;
                padding-right: 10px;
                .form_item {
                    margin: 0;
                    .form_input_price {
                        margin-left: 10px;
                        padding: 6px;
                        width: 130px;
                    }
                    .error_text {
                        font-weight: bold;
                    }
                }
                .btn_pay_all {
                    position: absolute;
                    top: 36px;
                    right: 198px;
                    padding: 0;
                    color: rgb(0, 123, 176);
                    font-size: 14px;
                    border: none;
                    background-color: transparent;
                    outline: none;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .btn_wrapper {
                    outline: none;
                    border: none;
                    background: none;
                }
                .error_text_position {
                    font-size: 13px;
                    position: absolute;
                    bottom: -15px;
                    left: 110px;
                }
            }
        }
    }
    .popup-content {
        padding: 16px !important;
        width: 520px !important;
        position: relative;
        padding: 5px;
    }
    .list_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-right: 33px;
        }
    }
    .list_content {
        max-height: 250px;
        overflow-x: auto;
        padding-right: 16px;
        .btn-wrapper {
            display: flex;
            justify-content: flex-end;
            margin: 8px 0;
        }
    }
    .unpaid{
        color: red;
    }
    .exit {
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #474747;
        border: 1px solid rgba(71, 71, 71, 0.6);
        box-sizing: border-box;
        border-radius: 4px;
        width: 160px;
        height: 32px;
        margin-top:16px ;
        cursor: pointer;
    }
}

.loader_wrapper_pay_popup {
    position: absolute;
    top: 70px;
    left: 205px;
    z-index: 999;
}

@media screen and (max-width: 600px) {
    .pay-pop-up-wrapper {
        .popup-content {
            width: 280px !important;
        }
        .pay-pop-up-header {
            margin-bottom: 35px;
            .title {
                display: none;
            }
            .pay_all {
                .form_pay_all {
                    .error_text_position {
                        bottom: -19px;
                        left: 116px;
                    }
                }
            }
        }
    }
    .loader_wrapper_pay_popup {
        left: 80px;
    }
}