.admin_dashboard_wrapper {
    padding: 0 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    .header {
        display: flex;
        justify-content: space-between;
        margin: 28px 16px;
        .tabs {
            display: flex;
            font-size: 18px;
            line-height: 21px;
            align-items: center;
            color: rgba(0, 0, 0, 0.5);
             .item{
                 cursor: pointer;
                 font-weight: bold;
                 margin-right: 32px;
                    
                &.active {
                    border-bottom: 1px solid #007BB0;
                    color: #007BB0;
                }
                &:hover{
                    border-bottom: 1px solid #007BB0;
                }
             }
        }
        .search {
            input {
                border: 1px solid rgba(172, 172, 172, 0.6);
                box-sizing: border-box;
                border-radius: 8px;
                height: 32px;
                width: 272px;
                font-size: 12px;
                padding-left: 28px;
            }
            img {
                position: absolute;
                margin-top: 6px;
                margin-left: 7px;
            }
        }
    }
    .pagination_container{
       display: flex;
       justify-content: flex-end;
       margin: 20px 10px 20px 0;
        &_top {
            margin: 10px 10px 5px 0;
        }
    }
}
