.footer {
    background: #526477;
    text-align:center;
    flex-shrink: 0;
    &_wrapper {
        max-width: 1024px;
        margin: 7px auto 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        flex-wrap: wrap;
    }
    .title {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.65);
        font-weight: 400;
    }
    .item {
        font-size: 16px;
        color: #FFFFFF;
        line-height: 21px;
        font-weight: 400;
        margin: 6px 0;
    }
    .hidden {
        visibility: hidden;
    }
    .item_position {
        display: inline-block;
        width: 85px;
    }
    .links {
        display: inline-block;
        text-align: left;
        margin: 0 3px;
        .item {
            cursor: pointer;
            transition: all ease-in-out 0.1s;
            &:hover {
                color: bisque;
            }
            &.active {
                color: bisque;
            }
        }
    }
    .contacts{
        display: inline-block;
        text-align: left;
        margin: 0 3px;
    }
    .footer_copyright {
        color: white;
        text-align: center;
        max-width: 400px;
        margin: 7px auto;
        .footer_copyright_text {
            font-size: 13px;
            margin: 0;
        }
        .footer_copyright_company {
            margin-top: 2px;
            display: block;
        }
        .footer_copyright_link {
            color: white;
            margin-left: 4px;
        }
    }
}

@media screen and (max-width: 720px) {
    .footer {
        .contacts{
            display: inline-block;
            text-align: left;
            .item {
                .footer_number {
                    margin-top: 6px;
                    display: block;
                }
            }
        }
    }
}