.constacts_wrapper {
    flex: 1 0 auto;
    .contacts_page {
        max-width: 1200px;
        margin: 40px auto 60px auto;
        padding: 0 20px;
        .title {
            font-size: 36px;
            line-height: 42px;
            color: #01000F;
            margin: 0 0 20px 0;
        }
        .contacts_items {
            margin: 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .item {
                margin-right: 5px;
                .item_title {
                    font-size: 20px;
                    line-height: 28px;
                    color: #000000;
                }
                .subitem {
                    display: flex;
                    align-items: center;
                    padding-bottom: 8px;
                    a {
                        text-decoration: none;
                        color: blue;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    img {
                        padding-right: 8px;
                    }
                }
            }
        }
    }    
}