.personal_info_wrapp {
    margin: 24px 0px 24px 24px;
    .form {
        .title {
            font-size: 24px;
            color: #474747;
        }
        .form_item {
            .form_label {
                font-size: 12px;
                line-height: 14px;
                color: #474747;
            }
            .form_input {
                border: 1px solid rgba(172, 172, 172, 0.6);
                box-sizing: border-box;
                border-radius: 2px;
                width: 266px;
                height: 32px;
                padding: 0 8px;
                &.error{
                    border: 1px solid rgba(157, 16, 16, 0.75);
                }
            }
        }
        .next_btn {
            margin-left: 266px;
            border: none;
            background: none;
            &:focus{
                outline: none;
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .personal_info_wrapp {
        .form {
            .title {
                font-size: 20px;
            }
            .next_btn {
                margin: 15px 0 0 180px;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .personal_info_wrapp {
        .form {
            .form_item {
                .form_input {
                    width: 200px;
                }
            }
            .next_btn {
                margin: 15px 0 0 100px;
            }
        }
    }
}