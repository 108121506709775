.settings_wrapper {
    flex: 1 0 auto;
    width: 900px;
    margin: 0 auto 30px auto;
    padding: 0 10px;
    .line {
        display: flex;
        justify-content: space-between;
    }
    .flex_end {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
    }
    .change_wrapper{
        margin: 30px 0 0 0;
        display: flex;
        .change_item {
            cursor: pointer;
            margin: 0 10px;
            font-size: 16px;
            font-weight: bold;
            color: rgb(0, 123, 176);
        }
    }
    .saved{
        text-align: center;
        color: green;
        font-size: 12px;
        font-weight: bold;
        margin: 0 0 10px 0;
    }
}

@media screen and (max-width: 1000px) {
    .settings_wrapper {
        max-width: 500px;
        margin-top: 30px;
        .line {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 560px) {
    .settings_wrapper {
        max-width: 300px;
    }
}