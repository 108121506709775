.home_info_wrapp {
    margin: 24px 0px 24px 24px;
    .form {
        .title {
            font-size: 24px;
            color: #474747;
            display: inline-block;
            padding-right: 20px;
        }
        .form_label {
            font-size: 12px;
            line-height: 14px;
            color: #474747;
        }
        .line {
            display: flex;
            .form_item {
                margin-right: 8px;
                .form_input {
                    border: 1px solid rgba(172, 172, 172, 0.6);
                    box-sizing: border-box;
                    border-radius: 2px;
                    width: 120px;
                    height: 32px;
                    padding: 0 8px;
                    &.error {
                        border: 1px solid rgba(157, 16, 16, 0.75);
                    }
                }
            }
        }
        .button_wrapper {
            margin-top: 160px;
            display: flex;
            align-items: center;
            .back {
                border: 1px solid rgba(71, 71, 71, 0.6);
                box-sizing: border-box;
                border-radius: 4px;
                height: 32px;
                padding: 0 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #474747;
                cursor: pointer;
            }
            .submit {
                margin-left: 190px;
                border: none;
                background: none;
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

@media screen and (max-width: 760px) {
    .home_info_wrapp {
        .form {
            .title {
                font-size: 20px;
            }
            .line {
                flex-direction: column;
                .form_item {
                    margin: 5px 0 20px 0;
                    .form_input {
                        width: 266px;
                    }
                }
            }
            .button_wrapper {
                margin-top: 42px;
                .submit {
                    margin-left: 80px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .home_info_wrapp {
        .form {
            .line {
                .form_item {
                    .form_input {
                        width: 200px;
                    }
                }
            }
            .button_wrapper {
                margin-top: 42px;
                .back {
                    width: 90px;
                    font-size: 14px;
                }
                .submit {
                    margin-left: 10px;
                }
            }
        }
    }
}