.basic_page_intro {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .intro_header {
        background: #526477;
        flex-shrink: 0;
        color: white;
        text-align: center;
        padding: 6px 0 8px;
        .intro_title {
            margin: 0;
            font-size: 28px;
            font-weight: normal;
        }
    }
    .intro_footer {
        background: #526477;
        flex-shrink: 0;
        text-align: center;
        padding-top: 10px;
        .intro_contacts {
            color: rgba(255, 255, 255, 0.8);
            font-size: 16px;
            max-width: 700px;
            margin: 0 auto;
            padding: 0 10px;
            .intro_number {
                color: white;
                margin: 3px 0 3px 4px;
            }
        }
        .footer_copyright {
            color: white;
            text-align: center;
            max-width: 400px;
            margin: 7px auto;
            .footer_copyright_text {
                font-size: 13px;
                margin: 0;
            }
            .footer_copyright_company {
                margin-top: 2px;
                display: block;
            }
            .footer_copyright_link {
                color: white;
                margin-left: 4px;
            }
        }
    }
}

@media screen and (max-width: 870px) {
    .basic_page_intro {
        .intro_footer {
            .intro_contacts {
                max-width: 250px;
                text-align: left;
                .intro_number {
                    display: block;
                    margin-left: 0;
                }
            }
            .footer_copyright {
                color: white;
                text-align: center;
                max-width: 400px;
                margin: 7px auto;
                .footer_copyright_text {
                    font-size: 13px;
                    margin: 0;
                }
                .footer_copyright_company {
                    margin-top: 2px;
                    display: block;
                }
                .footer_copyright_link {
                    color: white;
                    margin-left: 4px;
                }
            }
        }
    }
}