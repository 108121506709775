.admin_dashbaord_header {
    margin: 20px 15px 5px 15px;
    .tabs {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        line-height: 21px;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
        overflow-x: auto;
        .item {
            min-width: fit-content;
            cursor: pointer;
            font-weight: bold;
            margin-right: 15px;
            &.active {
                border-bottom: 2px solid #007BB0;
                color: #007BB0;            
            }
        }
    }
    .filter_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 15px;
        .filter{
            select {
                border: 1px solid rgba(172, 172, 172, 0.6);
                box-sizing: border-box;
                border-radius: 8px;
                height: 32px;
                font-size: 12px;
            }
        }
        .search {
            margin-right:8px ;
            input {
                border: 1px solid rgba(172, 172, 172, 0.6);
                box-sizing: border-box;
                border-radius: 4px;
                height: 32px;
                width: 200px;
                font-size: 12px;
                padding-left: 28px;
            }
            img {
                position: absolute;
                margin-top: 6px;
                position: absolute;
                margin-left: 7px;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .admin_dashbaord_header {
        .filter_wrapper {
            .search {
                input {
                    width: 125px;
                }
            }
        }
    }
}
