.instructions_wrapper {
    flex: 1 0 auto;
    .instructions {
        max-width: 1200px;
        margin: 40px auto 60px auto;
        padding: 0 20px;
        .title {
            margin: 0 0 30px 0;
            font-size: 36px;
            line-height: 42px;
            color: #000000;
            font-weight: bold;
        }
        .info {
            .subtitle {
                font-weight: bold;
                width: 650px;
                font-size: 18px;
                line-height: 21px;
                display: flex;
                align-items: center;
                color: #000000;
                padding-bottom: 4px;
                border-bottom: 2px solid #d1d1d1;
                width: 100%;
            }
            .desc {
                display: block;
                margin: 0 10px 4px 24px;
                font-size: 16px;
                line-height: 19px;
                font-weight: normal; 
            }
            .show_agreenment {
                display: inline;
                color: rgb(0, 123, 176);
                font-size: 18px;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}