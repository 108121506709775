.billing-data {
    width: 386px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 24px;
    margin: 8px;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            font-weight: bold;
        }
    }
    .content {
        margin: 0;
        .price {
            display: flex;
            justify-content: center;
            .price_money {
                font-size: 48px;
                line-height: 56px;
                text-align: center;
                color: #007BB0;
            }
            .price_currency {
                font-size: 24px;
                line-height: 62px;
                text-align: right;
                color: #007BB0;
                height: 78px;
            }
        }
        .pay_button{
            margin-left: auto;
            display: table;
            margin-right: auto;
            padding-top: 8px;
        }
    }
}

@media screen and (max-width: 800px) {
    .billing-data {
        width: 75%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .billing-data {
        width: 100%;
        padding: 15px;
        .content {
            .price {
                .price_money {
                    font-size: 38px;
                }
            }
        }
    }
}