.pay-data {
    width: 468px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 24px;
    margin: 8px;
    .header {
        .title {
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            font-weight: bold;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .content {
        margin: 0;

       

        .content_header {
            margin: 16px 0px 20px 0px;
            display: flex;
            justify-content: space-between;
            span {
                font-size: 18px;
                line-height: 21px;
                color: #474747;
                display: block;
            }
        }
        .content_footer {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .show_all {
                font-size: 14px;
                line-height: 16px;
                cursor: pointer;
                color: #007BB0;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .pay-data {
        width: 75%;
        margin: 0;
    }
}

@media screen and (max-width: 600px) {
    .pay-data {
        width: 100%;
        padding: 15px;
    }
}

.list_items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #000000;
    font-weight: bold;
    padding: 8px 0px;

    span{
        font-size: 14px;
        display: block;
        width: 100%;
        max-width: 110px;
    }

}